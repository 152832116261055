import ReactGA from "react-ga4";

export const initGoogleAnalytics = () => {
  const trackingId = "G-Z81FB5M3NK"; // Replace with your Google Analytics Measurement ID
  ReactGA.initialize(trackingId);
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
