import React, { useEffect, useState } from "react";
import "./App.css";
import "@fontsource/poppins";
import "react-toastify/dist/ReactToastify.css";
import { initGoogleAnalytics, trackPageView } from "./lib/googleAnalytics";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterComponent } from "./component/common/RouterComponent";
import "./css/style.css";
import Preloader from "./component/preloader/preloader"; // Import the Preloader component

function AppContent() {
    const [loading, setLoading] = useState(true); // State to manage loading
    const location = useLocation(); // Get location object from react-router-dom

    useEffect(() => {
        initGoogleAnalytics();
        trackPageView(location.pathname + location.search); // Track initial page load
    }, []);

    useEffect(() => {
        trackPageView(location.pathname + location.search); // Track route changes
    }, [location]);

    useEffect(() => {
        // Set localStorage items
        localStorage.setItem("chatModelProvider", "ollama");
        localStorage.setItem("chatModel", "llama3:latest");
        localStorage.setItem("embeddingModelProvider", "ollama");
        localStorage.setItem("embeddingModel", "llama3:latest");

        // Simulate a loading delay for the preloader (2 seconds)
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after 2 seconds
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    return (
        <>
            {loading && <Preloader />} {/* Show preloader while loading is true */}
            {!loading && <RouterComponent />} {/* Once loading is complete, show the app */}
        </>
    );
}

export default function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}
